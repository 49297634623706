<template>
  <v-form ref="WhatsAppForm" @submit.prevent="handleSave">
    <v-container>
      <v-row>
        <v-col>
          <div class="mb-4">
            <div class="font-weight-medium text-h6">
              {{ $trans("whatsapp_add_title") }}
            </div>
            {{ $trans("whatsapp_add_info") }}
          </div>
          <phone-input
            v-model="whatsappNumber"
            :rules="[rules.required]"
            :disabled="isSaving || isLoading"
            :default-country-code="$config('preferred_phone_countries')[0]"
            @enter="handleSave"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center">
          <v-btn
            v-if="!isEnabling"
            color="error"
            outlined
            :disabled="isSaving || isLoading"
            text
            @click="handleDisconnectWhatsapp"
          >
            <span v-text="$trans('disconnect_button_title')" />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import PhoneInput from "@/components/Forms/PhoneInput/PhoneInput";
import { required } from "@/lib/calendesk-js-library/forms/validators";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "WhatsApp",
  components: { PhoneInput },
  mixins: [sharedActions],
  data() {
    return {
      isSaving: false,
      isLoading: false,
      whatsappNumber: null,
      isEnabling: false,
      rules: {
        required,
      },
    };
  },
  created() {
    this.whatsappNumber = this.loggedUser.employee.whatsapp_number;
    this.isEnabling = !this.whatsappNumber;
  },
  methods: {
    ...mapActions({
      updateOwnEmployee: "employee/updateOwn",
      loadAuthUser: "setup/loadAuthUser",
      setRecentlyDisabledByUser: "employee/setWhatsappRecentlyDisabledByUser",
    }),
    async handleSave() {
      if (this.$refs.WhatsAppForm.validate()) {
        this.isSaving = true;
        this.$emit("ctaButtonLoading", true);

        try {
          await this.updateOwnEmployee({
            whatsapp_number: this.whatsappNumber,
          });

          await this.loadAuthUser();
          this.setRecentlyDisabledByUser(false);
          successNotification("update_success");
          this.$emit("close");
        } catch (e) {
          this.$emit("ctaButtonLoading", false);
          errorNotification(null, e);
        }

        this.isSaving = false;
      } else {
        errorNotification("form_is_invalid", {}, false);
      }
    },
    handleDisconnectWhatsapp() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("whatsapp_disconnect_dialog_title"),
        warning: this.$trans("whatsapp_calendar_disconnect_dialog_info"),
        confirmationText: this.$trans("disconnect_button_title"),
        confirmationButtonColor: "error",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);

          this.updateOwnEmployee({
            whatsapp_number: null,
          })
            .then(() => {
              this.loadAuthUser();
              this.setRecentlyDisabledByUser(true);
              this.closeConfirmDialog();
              this.$emit("close");
              successNotification("whatsapp_disconnect_dialog_title");
            })
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
              this.$emit("close");
            });
        },
      });
    },
  },
};
</script>
